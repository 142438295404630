import './App.css';
import herologo from './images/hero.svg';
import { Link, Element } from 'react-scroll';
import { useState } from 'react';



function App() {
   
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavToggle = () => setIsNavCollapsed(!isNavCollapsed);

  const handleNavLinkClick = () => setIsNavCollapsed(true);

  
  return (
    <div>
      <nav className="navbar navbar-expand-lg  fixed-top">
        <div className="container align-items-center">
          <Link className="navbar-brand fw-bold " to='home' style={{fontColor:'#007acc'}}>
            Sai Mg Mg Nyan Win
          </Link>
          <button
            className={`navbar-toggler ${isNavCollapsed? 'collapsed':''}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleNavToggle}
          >

            <span className="navbar-toggler-icon"></span>
          </button>
          <div className= {`${isNavCollapsed ?'collapse':'hide'}  navbar-collapse `} id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item" >
                <Link className="nav-link" aria-current="page" to="hero" onClick={handleNavLinkClick} >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link"  to="services" onClick={handleNavLinkClick}>
                  Services
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="about" onClick={handleNavLinkClick} >
                  About
                </Link>
              </li>
              
            </ul>
            <Link to='contact' className="btn btn-outline-dark " onClick={handleNavLinkClick}>
            Contact Me Today
            </Link>
          </div>
        </div>
      </nav>
      <Element name="hero" className="element">
        <section id="hero" className="hero">
          <div className="container ">
            <div className="row g-4">
              <div className="col-md-6  align-items-center">
                <h1 className="display-5 fw-bold">Bringing Your Ideas to Life with React Native and React.js</h1>
                <p>
                Freelance developer creating high-performance mobile apps with React Native and dynamic websites with React.js. Let’s bring your ideas to life with fast, responsive digital solutions
                </p>
                <Link to='contact' className="btn btn-outline-dark">
                Contact Me Today
                </Link>
              </div>
              <div className="col-md-6">
                <img src={herologo} className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </Element>

      <Element name="services" className="element">
        <section id="services" className="services">
          <div className="container">
            <h2 className="text-center">Services</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fw-bold">
                      Moblie App Development
                    </h5>
                    <p className="card-text">
                    I develop Android and iOS apps using React Native and JavaScript, with or without Expo. Let’s create efficient, cross-platform mobile solutions customized for your needs.                    </p>
                    <Link to='contact' class="btn btn-outline-dark">
                    Contact Me Today
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fw-bold">Website Development</h5>
                    <p className="card-text">
                    I create websites using React.js, JavaScript, and Bootstrap, delivering responsive and dynamic web experiences. Let’s build a visually stunning, high-performance site that achieves your business objectives.                    </p>
                    <Link to='contact' class="btn btn-outline-dark">
                    Contact Me Today
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fw-bold">
                      Cross(Desktop) Platflom Development
                    </h5>
                    <p className="card-text">
                    I develop cross-platform software for Mac, Linux, and Windows using React.js, JavaScript, and Electron.js. Let’s build applications with a seamless user experience and robust functionality.                    </p>
                    <a class="btn btn-outline-dark disabled">Coming Soon</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>

      <Element name="about" className="element">
        <section className='about'>

        <div className="container text-center">
          <div className="row g-5">
            <div className="col-md-6 text-start">
              <h2 className="h4">About</h2>
              <p>
              I’m a freelance developer skilled in React.js, React Native, and Electron.js, creating responsive web, mobile, and desktop applications using JavaScript.
              </p>
              <p>Email : <a style={{color:'#007acc'}}>saimgmg.nyanwin@smmnw.com</a></p>
              <p>Website : <a href='#' style={{color:'#007acc'}}>smmnw.com</a></p>
             
            </div>
            <div className='col-md-6 text-start'>
                  <h2>Certificate</h2>
                  <ul >
                    <li >Building Your Freelancing Career <a className='btn btn-outline-dark' href='https://coursera.org/verify/specialization/QPV5765HYJF8'>More</a></li>
                    <li >Meta React Native <a className='btn btn-outline-dark' href='https://coursera.org/verify/specialization/QRUR3SEPKVR5'>More</a></li>
                    <li>Meta Front-End Developer <a className='btn btn-outline-dark' href='#'>More</a></li>
                  </ul>
            </div>
          </div>
        </div>
        </section>
      </Element>

      <Element name='contact' className='element'>
         <section className='contact'>
          <div className='container'>
            <form>
            <div className='form-group mt-4'>
            <label className='form-text'for='name'>Name</label>
            <input type='text' className='form-control mt-2' id='name'/>
            </div>

            <div className='form-group mt-4'>
            <label className='form-text' for='name'>Email</label>
            <input type='text' className='form-control mt-2' id='email'/>
            </div>
            <div className='form-group mt-4'>
             <label for='service'>Select Service</label>
             <select id='service' className='form-select mt-2'>
                <option>Moblie App (Android & IOS) Development</option>
                <option >Website Development</option>
             </select>
            </div>
            <button  className='btn btn-outline-dark mt-4'>Submit</button>
            </form>
          </div>
         </section>
      </Element>
      <footer className="footer" id="footer" style={{background:'#ecf2ff'}}>
        <div className="text-center border border-top pt-3">
          <p>&copy; 2024 Sai Mg Mg Nyan Win</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
